import { User } from 'commons/types/user.interface';
import { UserRolesEnum } from 'commons/types/user.roles.enum';

// to determine default root url
export const getRootUrl = (user: User): string => {
  switch (user.role) {
    case UserRolesEnum.PICKER:
    case UserRolesEnum.PICKER_LEAD:
    case UserRolesEnum.PICKER_SPV:
      return '/assign-task';
    case UserRolesEnum.PACKER:
    case UserRolesEnum.PACKER_LEAD:
    case UserRolesEnum.PACKER_SPV:
      return '/pack-list';
    case UserRolesEnum.ADMIN:
    case UserRolesEnum.SUPER_ADMIN:
      return '/home';
    case UserRolesEnum.REPLENISH:
      return '/assign-replenish';
    default:
      return '/not-found';
  }
};
