import styled from 'styled-components';

export const Button = styled.button`
  background: none;
  color: #67d1bd;
  border: 2px solid #67d1bd;
  border-radius: 8px;
  cursor: pointer;
  width: 40vw;
  padding: 15px;
  font-size: 18px;
  margin-bottom: 16px;
`;
