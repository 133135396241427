import { Reducer } from 'redux';

import {
  DashboardState,
  PickingTaskState,
  ReplenishTaskState,
} from 'commons/types';
import { UtilityState } from 'redux-stores/interfaces';

import { dashboardReducer } from './dashboard.reducer';
import pickingTaskReducer from './pickingTaskReducer';
import { replenishTaskReducer } from './replenishTaskReducer';
import utilityReducer from './utilityReducer';

enum ReducerKeys {
  PickingTask = 'pickingTask',
  Utility = 'utility',
  Dashboard = 'dashboard',
  ReplenishTask = 'replenishTask',
}

export interface RootReducerInterface extends Record<ReducerKeys, unknown> {
  [ReducerKeys.PickingTask]: PickingTaskState;
  [ReducerKeys.Utility]: UtilityState;
  [ReducerKeys.Dashboard]: DashboardState;
  [ReducerKeys.ReplenishTask]: ReplenishTaskState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer: Record<ReducerKeys, Reducer<any>> = {
  [ReducerKeys.ReplenishTask]: replenishTaskReducer,
  [ReducerKeys.PickingTask]: pickingTaskReducer,
  [ReducerKeys.Utility]: utilityReducer,
  [ReducerKeys.Dashboard]: dashboardReducer,
};

export default rootReducer;
