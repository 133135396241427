import { NavigateFunction } from 'react-router-dom';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  PRIMARY_BLUE,
  PRIMARY_GREEN,
  PRIMARY_RED,
} from 'commons/styles/colors';
import {
  PickingListBySKUs,
  PickingRecommendation,
  PickingTask,
} from 'commons/types';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { RootReducerInterface } from 'redux-stores/reducers';
import {
  addAssignedTask,
  removeAssignedTask,
  setAssignedTask,
  updateAssignedTask,
  updateTaskBySKU,
  updateTaskBySKUPickRecommendation,
} from 'redux-stores/reducers/pickingTaskReducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';
import { swipeRxWmsApiAssignBasket } from 'services/apis/SwipeRxWmsApiAssignBasket';
import { BasketSizeEnum } from 'services/apis/SwipeRxWmsApiAssignBasket/interfaces';
import { swiperxWmsApiPickJob } from 'services/apis/SwipeRxWmsApiPickJob';
import {
  IPickingJobPickedItemRequest,
  IPickingJobProductDetailRequest,
  IPickingJobProductDetailResponse,
  IPickingJobRecommendationRequest,
  IPickingJobRemoveBatchRequest,
} from 'services/apis/SwipeRxWmsApiPickJob/types';
import { swipeRxWmsApiPickList } from 'services/apis/SwipeRxWmsApiPickList';
import { IAssignBasketRequest } from 'services/apis/SwipeRxWmsApiPickList/interfaces';

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getPickingTasksAction = createAsyncThunk(
  'pickingTask/getPickingTaskAction',
  async (_, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiPickList.getPicklists();
      dispatch(setAssignedTask(data));
    }, dispatch);
    return undefined;
  },
);

export const actionSetAssignedTask = createAsyncThunk(
  'pickingTask/actionSetAssignedTask',
  async (_, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiPickList.assignPickingTask();
      dispatch(setAssignedTask(data));
    }, dispatch);
    return undefined;
  },
);

export const actionAddAssignedTask = createAsyncThunk(
  'pickingTask/actionAddAssignedTask',
  async (_, { dispatch, getState }) => {
    await defaultActionProcess(async () => {
      const state: RootReducerInterface = getState() as RootReducerInterface;
      const data = await swipeRxWmsApiPickList.addPickingTask({
        order_type: state.pickingTask.assignedTasks[0].po_type,
      });

      dispatch(addAssignedTask(data));
    }, dispatch);
    return undefined;
  },
);

export const actionRemoveAssignedTask = createAsyncThunk(
  'pickingTask/actionRemoveAssignedTask',
  async (poNumber: string, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiPickList.removePickingTask(poNumber);
      dispatch(removeAssignedTask(data));
    }, dispatch);
    return undefined;
  },
);

// called without using dispatch
export const actionStartPickingTask = createAsyncThunk(
  'pickingTask/startPickingTask',
  async (
    payload: { tasks: PickingTask[]; navigate: NavigateFunction },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiPickList.startPickingProcess(
        payload.tasks.map((task) => task.po_number),
      );
      if (data !== 'ok') {
        dispatch(
          snackbarSetData({
            open: true,
            message: data,
            color: PRIMARY_BLUE,
          }),
        );
      }
      payload.navigate('/pick-list');
    }, dispatch);
    return undefined;
  },
);

export const assignBasket = createAsyncThunk(
  'pickingTask/assignBasket',
  async (params: IAssignBasketRequest, { dispatch }) => {
    const { task, updateState } = params;
    defaultActionProcess(async () => {
      const payload = { po_number: task.po_number, baskets: task.baskets };
      await swipeRxWmsApiAssignBasket.assignBasketsToAPI(payload);
      if (updateState) dispatch(updateAssignedTask(task as PickingTask));
    }, dispatch);
  },
);

export const getBasketRecommendation = async (
  poNumber: string,
): Promise<string> => {
  const result =
    await swipeRxWmsApiAssignBasket.getBasketRecommendation(poNumber);
  if (!result) return '';

  const recommendations: string[] = [];
  const sizes = Object.values(BasketSizeEnum);
  for (let i = 0; i < sizes.length; i += 1) {
    if (result[sizes[i]] > 0) {
      recommendations.push(`${sizes[i].charAt(0)}: ${result[sizes[i]]}`);
    }
  }

  return recommendations.join(', ');
};

export const getPickTaskBySKU = createAsyncThunk(
  'pickingTask/getPickTaskBySKU',
  async (_, { dispatch }) => {
    defaultActionProcess(async () => {
      const response: PickingListBySKUs[] =
        await swiperxWmsApiPickJob.getPickJobs();
      dispatch(updateTaskBySKU(response));
    }, dispatch);
  },
);

export const getRecommendation = createAsyncThunk(
  'pickingTask/getRecommendation',
  async (params: IPickingJobRecommendationRequest, { dispatch }) => {
    if (!params.skuCode) return;
    const response = await swiperxWmsApiPickJob.getRecommendation(params);
    let pickingInfoPayload: {
      sku_code: string;
      pick_infos?: PickingRecommendation[];
    };
    if (response && response.length > 0) {
      pickingInfoPayload = {
        sku_code: response[0].sku_code,
        pick_infos: response.map((data) => ({
          bin: data.bin,
          expiry_date: data.expiry_date.toString(),
          inventory_number: data.inventory_number,
          quantity: data.quantity,
          bin_type: data.bin_type,
          is_preferred: data.is_preferred,
        })),
      };
    } else {
      pickingInfoPayload = { sku_code: params.skuCode };
    }
    dispatch(updateTaskBySKUPickRecommendation(pickingInfoPayload));
  },
);

export const getProductDetail = async (
  params: IPickingJobProductDetailRequest,
  dispatch: AppDispatch,
): Promise<IPickingJobProductDetailResponse | null> => {
  const response = await swiperxWmsApiPickJob.getProductDetail(params);
  if (response && response.data) {
    return response.data;
  }
  if (response.error) {
    const errorMessage =
      response.error === true
        ? 'Failed to get product detail. Please make sure SKU, Bin, and Inventory Number is correct.'
        : response.error;
    dispatch(
      snackbarSetData({
        open: true,
        message: errorMessage,
        color: PRIMARY_RED,
      }),
    );
  }
  return null;
};

export const postPickedItem = async (
  params: IPickingJobPickedItemRequest,
  dispatch: AppDispatch,
): Promise<void> => {
  const response = await swiperxWmsApiPickJob.postPickedItem(params);
  if (!response) {
    dispatch(
      snackbarSetData({
        open: true,
        message:
          'Failed to get pick item. Please make sure SKU, Bin, Inventory Number, and Quantity is correct.',
        color: PRIMARY_RED,
      }),
    );
  }
};

export const postRemoveBatch = async (
  params: IPickingJobRemoveBatchRequest,
): Promise<void> => {
  await swiperxWmsApiPickJob.postRemoveBatch(params);
};

export const validatePickingList = (
  params: PickingListBySKUs[],
): { validation: 'success' | 'error' | 'warning'; message?: string } => {
  let validation: {
    validation: 'success' | 'error' | 'warning';
    message?: string;
  } = {
    validation: 'success',
  };
  params.forEach((pickItem) => {
    const { details, total_quantity: totalQuantity } = pickItem;
    const currentQuantity = details.reduce((total, detail) => {
      const qty = detail.picked_actual.reduce(
        (pickedTotal, picked) => pickedTotal + picked.quantity,
        0,
      );
      return total + qty;
    }, 0);
    if (totalQuantity > currentQuantity && validation.validation !== 'error') {
      validation = {
        validation: 'warning',
        message: 'Some SKUs is not yet picked',
      };
    } else if (totalQuantity < currentQuantity) {
      validation = {
        validation: 'error',
        message: 'Some SKUs have incorrect quantity',
      };
    }
  });
  return validation;
};

export const postComplete = async (): Promise<{
  open: true;
  message: string;
  color: string;
  success: boolean;
}> => {
  const result = await swiperxWmsApiPickJob.postCompletePick();
  if (!result) {
    return {
      open: true,
      message: 'Picking Task Failed',
      color: PRIMARY_RED,
      success: false,
    };
  }
  return {
    open: true,
    message: 'Picking Task Processed',
    color: PRIMARY_GREEN,
    success: true,
  };
};
