export enum PickingTaskStatusEnum {
  QUEUE = 'queue',
  PICKING = 'picking',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update-failed',
  PICKED = 'picked',
  CANCELLED = 'pick-cancelled',
}

export enum PickingTaskSearchCriteriaEnum {
  QUEUE = 'queue',
  ASSIGNED = 'assigned',
  PICKING = 'picking',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update-failed',
  PICKED = 'picked',
  CANCELLED = 'pick-cancelled',
}
