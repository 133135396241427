import React from 'react';
import { useSelector } from 'react-redux';

import { Header } from 'commons/components/Header';
import { selectUser } from 'redux-stores/features/authSlice';

import S from '../HomePage.styles';

const UserInfoHeader: React.FC = () => {
  const user = useSelector(selectUser);

  return (
    <>
      <Header />
      <S.SubTitle>
        {user.userName.toLocaleUpperCase()}
        {' | '}
        {user.warehouse?.toLocaleUpperCase()}
      </S.SubTitle>
    </>
  );
};

export default UserInfoHeader;
