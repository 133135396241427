import React from 'react';

import {
  AddShoppingCartOutlined,
  CheckCircleOutline,
} from '@mui/icons-material';

import { PickingItem } from 'commons/types/pickTask.interface';
import { TableConfig } from 'commons/types/tableConfig.interface';

export const config: TableConfig = [
  {
    header: 'Item',
    key: 'sku',
  },
  {
    header: 'Name',
    key: 'name',
  },
  {
    header: 'Quantity',
    key: 'quantity',
  },
  {
    header: 'Pick Status',
    element: (item: PickingItem) => {
      // check if picked item === quantity
      if (item.picked_actual && item.picked_actual.length > 0) {
        const picked = item.picked_actual.reduce<number>(
          (sum, data) => sum + data.quantity,
          0,
        );
        if (picked === item.quantity)
          return <CheckCircleOutline style={{ color: '#5bad5a' }} />;
      }

      return <AddShoppingCartOutlined style={{ color: '#979797' }} />;
    },
  },
];
