/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import {
  PickingListBySKUs,
  PickingListBySKUsUpdatePayload,
  PickingRecommendation,
  PickingTask,
  PickingTaskState,
} from 'commons/types';

const initialState: PickingTaskState = {
  tasks: [],
  assignedTasks: [],
  tasksBySKUs: [],
  skuList: [],
  skuLowStockTriggered: {},
};

const pickingTaskSlice = createSlice({
  name: 'pickingTask',
  initialState,
  reducers: {
    setAssignedTask(
      state: PickingTaskState,
      action: { type: string; payload: PickingTask[] },
    ) {
      state.assignedTasks = action.payload;
    },
    addAssignedTask(
      state: PickingTaskState,
      action: { type: string; payload: PickingTask },
    ) {
      state.assignedTasks.push(action.payload);
    },
    removeAssignedTask(
      state: PickingTaskState,
      action: { type: string; payload: PickingTask },
    ) {
      const updatedTasks = state.assignedTasks.filter(
        (task) => task.po_number !== action.payload.po_number,
      );
      state.assignedTasks = updatedTasks;
    },
    updateAssignedTask(
      state: PickingTaskState,
      action: { type: string; payload: PickingTask },
    ) {
      const updatedTasks = state.assignedTasks.map((task) =>
        task.po_number === action.payload.po_number ? action.payload : task,
      );
      state.assignedTasks = updatedTasks;
    },
    updateTaskBySKU(
      state: PickingTaskState,
      action: { type: string; payload: PickingListBySKUs[] },
    ) {
      state.tasksBySKUs = action.payload;
    },
    updateSKUList(
      state: PickingTaskState,
      action: { type: string; payload: string[] },
    ) {
      state.skuList = action.payload;
    },
    updateTaskBySKUPickedActual(
      state: PickingTaskState,
      action: { type: string; payload: PickingListBySKUsUpdatePayload },
    ) {
      const { payload } = action;
      const updatedTaskBySkus = state.tasksBySKUs.map((task) => ({
        ...task,
        details: task.details.map((detail) => ({
          ...detail,
          picked_actual: payload[detail.po_number]
            ? [...payload[detail.po_number]]
            : detail.picked_actual,
        })),
      }));
      state.tasksBySKUs = updatedTaskBySkus;
    },
    updateTaskBySKUPickRecommendation(
      state: PickingTaskState,
      action: {
        type: string;
        payload: {
          sku_code: string;
          pick_infos?: PickingRecommendation[];
        };
      },
    ) {
      const { sku_code: skuCode, pick_infos: pickInfos } = action.payload;
      const pickRecommendation = pickInfos || [];
      const updatedTaskBySkus = state.tasksBySKUs.map((task) => {
        if (task.sku_code === skuCode) {
          return {
            ...task,
            pick_recommendation: pickRecommendation,
          };
        }
        return task;
      });
      state.tasksBySKUs = updatedTaskBySkus;
    },
    /**
     * To give temporary flag to sku that is stock out
     * whether picker has triggered the replenish button request or not
     * @param state
     * @param action
     */
    updateTaskBySKUReplenishRequested(
      state: PickingTaskState,
      action: {
        type: string;
        payload: {
          sku_code: string;
          replenish_requested: boolean;
        };
      },
    ) {
      const { sku_code: skuCode, replenish_requested: replenishRequested } =
        action.payload;
      const updatedData = state.skuLowStockTriggered;
      updatedData[skuCode] = replenishRequested;
      state.skuLowStockTriggered = updatedData;
    },
  },
});

export const {
  setAssignedTask,
  addAssignedTask,
  updateAssignedTask,
  removeAssignedTask,
  updateTaskBySKU,
  updateTaskBySKUPickRecommendation,
  updateTaskBySKUPickedActual,
  updateSKUList,
  updateTaskBySKUReplenishRequested,
} = pickingTaskSlice.actions;

export default pickingTaskSlice.reducer;
