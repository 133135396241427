import {
  DashboardActiveLocation,
  DashboardActiveOrderSummaries,
  DashboardShiftSummaries,
} from 'commons/types';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import {
  BulkPrioritizeResProps,
  OrderListResponse,
  ReplenishListResponse,
  UserAPIResponse,
  UserListResponse,
} from './types';
import { IBulkUpdateRetryResProps } from './types/update-retry.interface';

class SwipeRxWmsApiDashboard extends SwipeRxWmsApi {
  private readonly basePath: string;

  constructor() {
    super(SwipeRxAPIResources.DASHBOARD);
    this.basePath = `/${this.apiResource}`;
  }

  async getOrderListPaginated(
    queries: Record<string, string | number>,
  ): Promise<OrderListResponse> {
    const result = await this.get(`${this.basePath}/order-list`, queries);
    if (result && result.data) {
      return result.data as OrderListResponse;
    }
    return [];
  }

  async getActiveOrderSummaries(
    location: number | undefined,
  ): Promise<DashboardActiveOrderSummaries | null> {
    const result = await this.get(`${this.basePath}/summaries/active-orders`, {
      location,
    });
    if (result && result.data) {
      return result.data as DashboardActiveOrderSummaries;
    }
    return null;
  }

  async getShiftSummaries(
    location: number | undefined,
  ): Promise<DashboardShiftSummaries | null> {
    const result = await this.get(`${this.basePath}/summaries/shift`, {
      location,
    });
    if (result && result.data) {
      return result.data as DashboardShiftSummaries;
    }
    return null;
  }

  async prioritizeOrderPick(
    poNumber: string,
    location?: number,
  ): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/prioritize-pick`, {
        location,
        po_number: poNumber,
      });
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async bulkPrioritizeOrderPick(
    poNumbers: string[],
    location?: number,
  ): Promise<BulkPrioritizeResProps> {
    try {
      const result = await this.post(`${this.basePath}/prioritize-pick/bulk`, {
        location,
        po_numbers: poNumbers,
      });
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async getReplenishListPaginated(
    queries: Record<string, string | number>,
  ): Promise<ReplenishListResponse> {
    const result = await this.get(`${this.basePath}/replenish-list`, queries);
    if (result && result.data) {
      return result.data as ReplenishListResponse;
    }
    return [];
  }

  async getUserListPaginated(
    queries: Record<string, string | number>,
  ): Promise<UserListResponse> {
    try {
      const result = await this.get(`${this.basePath}/users`, queries);
      return result.data.map((user: UserAPIResponse) => ({
        userName: user.name,
        role: user.role,
        warehouse: user.location,
        userStatus: user.user_status,
      }));
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async retryUpdate(poNumber: string, location?: number): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/retry`, {
        location,
        po_number: poNumber,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async bulkRetryUpdate(
    poNumbers: string[],
    location?: number,
  ): Promise<IBulkUpdateRetryResProps> {
    try {
      const result = await this.post(`${this.basePath}/retry/bulk`, {
        location,
        po_numbers: poNumbers,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getActiveLocations(): Promise<DashboardActiveLocation[]> {
    try {
      const result = await this.get(`${this.basePath}/active-locations`);
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async prioritizeReplenishTask(
    replenishNumber: string,
    location?: number,
  ): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/prioritize-replenish`, {
        location,
        replenish_number: replenishNumber,
      });
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async bulkPrioritizeReplenishTask(
    replenishNumbers: string[],
    location?: number,
  ): Promise<BulkPrioritizeResProps> {
    try {
      const result = await this.post(
        `${this.basePath}/prioritize-replenish/bulk`,
        {
          location,
          replenish_numbers: replenishNumbers,
        },
      );
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async retryUpdateReplenish(
    replenishNumber: string,
    location?: number,
  ): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/retry-replenish`, {
        location,
        replenish_number: replenishNumber,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async bulkRetryUpdateReplenish(
    replenishNumbers: string[],
    location?: number,
  ): Promise<IBulkUpdateRetryResProps> {
    try {
      const result = await this.post(`${this.basePath}/retry-replenish/bulk`, {
        location,
        replenish_numbers: replenishNumbers,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}

export const swiperxWmsApiDashboard = new SwipeRxWmsApiDashboard();
