import React from 'react';

import { DebouncedState } from 'use-debounce';

import { CriteriaOption } from 'commons/components/SearchRelated';
import {
  PickingTaskStatusEnum,
  ReplenishTaskStatusEnum,
  PickingTaskSearchCriteriaEnum,
  UserStatusEnum,
} from 'commons/enums';

import { PickingTask } from './pickTask.interface';
import { ReplenishTask } from './replenishTask.interface';
import { User } from './user.interface';

export interface ListPageProps {
  updateListSearchDebounce: DebouncedState<
    (_searchParams: any, location: any, _dispatch: any) => void
  >;
  searchCriteria?: CriteriaOption[];
}

export interface ListFilterCardProps {
  statusArrayText: { key: string; text: string }[];
  statusFilter:
    | UserStatusEnum
    | PickingTaskStatusEnum
    | ReplenishTaskStatusEnum
    | PickingTaskSearchCriteriaEnum
    | null;
  handleStatusFilter: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => void;
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface RowTitleProps {
  title: string;
  sortField?: string;
}

export interface DefaultSortProps {
  sortField: string;
  sortOrder: SortOrder;
}

export interface DataTableProps {
  handleCheckAll: () => void;
  checkAll: boolean;
  rowTitles: RowTitleProps[];
  defaultSort?: DefaultSortProps;
}

export interface ActionRowProps {
  dataName: string;
  selectedData: User[] | PickingTask[] | ReplenishTask[];
  anchorEl?: HTMLElement | null;
  openMenu?: boolean;
  handleClickMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface ChooseActionBtnProps {
  selectedData: User[] | PickingTask[] | ReplenishTask[];
  anchorEl?: HTMLElement | null;
  openMenu?: boolean;
  handleClickMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  dataName?: string;
}
