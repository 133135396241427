/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { SnackbarState, UtilityState } from '../interfaces';

const initialState: UtilityState = {
  snackbar: {
    color: '',
    message: '',
    autoHideDuration: 5000,
    open: false,
  },
  loading: {
    show: false,
  },
};

const snackbarSlice = createSlice({
  name: 'utility',
  initialState,
  reducers: {
    snackbarSetOpen(
      state: UtilityState,
      action: { type: string; payload: boolean },
    ) {
      state.snackbar.open = action.payload;
    },
    snackbarSetData(
      state: UtilityState,
      action: { type: string; payload: Partial<SnackbarState> },
    ) {
      state.snackbar.open = action.payload?.open || state.snackbar.open;
      state.snackbar.message =
        action.payload?.message || state.snackbar.message;
      state.snackbar.color = action.payload?.color || state.snackbar.color;
    },
    setLoading(
      state: UtilityState,
      action: { type: string; payload: boolean },
    ) {
      state.loading.show = action.payload;
    },
  },
});

export const { snackbarSetData, snackbarSetOpen, setLoading } =
  snackbarSlice.actions;

export default snackbarSlice.reducer;
