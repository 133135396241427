import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useDebouncedCallback } from 'use-debounce';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { ReplenishTaskStatusEnum, ReplenishType } from 'commons/enums';
import { ReplenishItemProps, ReplenishTask } from 'commons/types';
import {
  getReplenishTaskBySkuCode,
  getSourceProductDetail,
  postRemoveSourceBatch,
  postReplenishPickSourceBin,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { ReplenishItemForm } from './components';
import { ReplenishTaskStyle as S } from './ReplenishTask.style';
import { sourceRecommendationConfig } from './table-configs';

export const ReplenishTaskSourcePage: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pathSkuId = pathname.split('/').reverse()[0];
  const dispatch = useDispatch<AppDispatch>();
  const {
    replenish_tasks: replenishTasks,
    replenish_sku_list: replenishSkuList,
  } = useSelector((state: RootReducerInterface) => state.replenishTask);

  useEffect(() => {
    dispatch(
      getReplenishTaskBySkuCode({
        skuCode: pathSkuId,
        updateSourceRecommendation: true,
        navigate,
      }),
    );
  }, [dispatch, navigate, pathSkuId]);

  const replenishTask: ReplenishTask = useMemo(() => {
    const emptyReplenishTask: ReplenishTask = {
      /* fallback value, normally should not be assigned */
      sku_code: pathSkuId,
      source_bin_recommendations: [],
      source_bins: [],
      destination_bin_requested: [],
      destination_bins: [],
      quantity: 0,
      status: ReplenishTaskStatusEnum.REPLENISHING,
      type: ReplenishType.PICKER_REQUEST,
      replenish_number: '',
      retail_qty: 0,
      warehouse_id: 0,
      requestor: null,
      replenish_user: null,
      queued_at: null,
      assigned_at: null,
      replenished_at: null,
      completed_at: null,
    };
    return replenishTasks[pathSkuId] || emptyReplenishTask;
  }, [pathSkuId, replenishTasks]);

  const [skuIndex, setSkuIndex] = useState(0);
  useEffect(() => {
    setSkuIndex(replenishSkuList.findIndex((sku) => sku === pathSkuId));
  }, [pathSkuId, replenishSkuList]);

  const [sources, setSources] = useState<Partial<ReplenishItemProps>[]>(
    structuredClone(replenishTask.source_bins),
  );
  useEffect(() => {
    if (replenishTask?.source_bins && replenishTask.source_bins.length > 0) {
      setSources(structuredClone(replenishTask.source_bins));
    } else {
      setSources([{}]);
    }
  }, [replenishTask.source_bins]);

  const handleAddBatch = (): void => {
    const newSources = structuredClone(sources);
    newSources.push({});
    setSources(newSources);
  };

  const handleRemoveBatch = (): void => {
    const newSources = structuredClone(sources);
    const removedSource = newSources.pop();

    if (removedSource && removedSource.bin && removedSource.inventory_number) {
      postRemoveSourceBatch(
        {
          skuCode: replenishTask.sku_code,
          removedBin: {
            bin: removedSource.bin,
            inventory_number: removedSource.inventory_number,
          },
        },
        dispatch,
      );
    }
    setSources(newSources);
  };

  const debouncePostReplenishReplaceBatch = useDebouncedCallback(
    (skuCode: string, replacedSource: ReplenishItemProps) => {
      postRemoveSourceBatch(
        {
          skuCode,
          removedBin: {
            bin: replacedSource.bin,
            inventory_number: replacedSource.inventory_number,
          },
        },
        dispatch,
      );
    },
    500,
    { maxWait: 2000 },
  );

  const handleReplaceBatch = (
    index: number,
    newItem: Partial<ReplenishItemProps>,
  ): Partial<ReplenishItemProps> => {
    const newSources = structuredClone(sources);
    const replacedSource = newSources[index];
    if (
      replacedSource &&
      replacedSource.bin &&
      replacedSource.inventory_number &&
      replacedSource.quantity
    ) {
      debouncePostReplenishReplaceBatch(
        replenishTask.sku_code,
        replacedSource as ReplenishItemProps,
      );
    }
    newSources[index] = newItem;
    setSources(newSources);
    return newItem;
  };

  const debouncePostReplenishPickSourceBin = useDebouncedCallback(
    (skuCode: string, sourceBin: ReplenishItemProps) => {
      postReplenishPickSourceBin(
        {
          skuCode,
          sourceBin,
        },
        dispatch,
      );
    },
    500,
    { maxWait: 2000 },
  );

  const debounceGetProductDetail = useDebouncedCallback(
    async (
      skuCode: string,
      index: number,
      item: Partial<ReplenishItemProps>,
    ) => {
      const { bin, inventory_number: inventoryNumber } = item;
      if (bin && inventoryNumber) {
        const result = await getSourceProductDetail(
          { skuCode, sourceBin: { bin, inventory_number: inventoryNumber } },
          dispatch,
        );
        const newSources = structuredClone(sources);
        let newSource: Partial<ReplenishItemProps>;
        if (result) {
          newSource = {
            bin: result.bin,
            inventory_number: result.inventory_number,
            expiry_date: new Date(result.expiry_date),
            quantity: 0,
          };
        } else {
          newSource = { bin, inventory_number: inventoryNumber, quantity: 0 };
        }
        newSources[index] = newSource;
        setSources(newSources);
      }
    },
    500,
    {
      maxWait: 2000,
    },
  );

  const handleOnChange = async (
    index: number,
    newItem: Partial<ReplenishItemProps>,
  ): Promise<void> => {
    const currentSource = sources[index];
    if (!currentSource) {
      return;
    }
    const { bin, inventory_number: inventoryNumber, quantity } = newItem;
    if (
      bin === currentSource.bin &&
      inventoryNumber === currentSource.inventory_number &&
      quantity === currentSource.quantity
    ) {
      /* same data / no update, no need to update to backend */
      return;
    }
    if (bin && inventoryNumber && !quantity) {
      debounceGetProductDetail(replenishTask.sku_code, index, newItem);
    }
  };

  const handleUpdateSource = (
    index: number,
    newSource: ReplenishItemProps,
  ): void => {
    const {
      bin,
      inventory_number: inventoryNumber,
      expiry_date: expiryDate,
      quantity,
    } = newSource;
    const currentSource = sources[index];
    if (!currentSource) {
      return;
    }
    if (
      bin === currentSource.bin &&
      inventoryNumber === currentSource.inventory_number &&
      currentSource.expiry_date &&
      DateTime.fromJSDate(new Date(expiryDate)).hasSame(
        DateTime.fromJSDate(new Date(currentSource.expiry_date)),
        'day',
      ) &&
      quantity === currentSource.quantity
    ) {
      /* same data / no update, no need to update to backend */
      return;
    }
    if (bin && inventoryNumber && expiryDate && quantity) {
      const newSources = structuredClone(sources);
      newSources[index] = newSource;
      setSources(newSources);

      debouncePostReplenishPickSourceBin(replenishTask.sku_code, newSource);
    }
  };

  const handleBackToSkuListPage = (): void => {
    navigate('/replenish-list/source');
  };

  const [nextSku, setNextSku] = useState<string | null>(null);
  useEffect(() => {
    const nextSkuIndex = skuIndex + 1;
    if (
      nextSkuIndex >= 0 &&
      nextSkuIndex < replenishSkuList.length &&
      replenishSkuList[nextSkuIndex]
    ) {
      setNextSku(replenishSkuList[nextSkuIndex]);
    } else {
      setNextSku(null);
    }
  }, [replenishSkuList, skuIndex]);
  const handleGoToNextSku = (): void => {
    if (nextSku) {
      navigate(`/replenish-task/source/${nextSku}`);
    }
  };

  return (
    <>
      {/* Header */}
      <Header
        title="Replenish Task | Source"
        prevPageHandler={handleBackToSkuListPage}
      />

      {/* Body */}
      <S.ContentWrapper>
        <S.SubtitleWrapper container>
          <S.Subtitle item xs={12}>
            {pathSkuId}
          </S.Subtitle>
          <S.SubHeaderText>
            {replenishTask.product?.name || '-'} <br />
            Total Quantity Requested: {replenishTask.quantity}
          </S.SubHeaderText>
        </S.SubtitleWrapper>

        <S.ItemContentWrapper container>
          <Grid item xs={12}>
            <S.SubHeaderText>Source Bin</S.SubHeaderText>
          </Grid>
          {/* bin recommendation */}
          <TableComponent
            data={replenishTask.source_bin_recommendations || []}
            config={sourceRecommendationConfig}
            additionalTableConfig={{ bodyFontSize: '12px' }}
          />
        </S.ItemContentWrapper>
        {/* main forms */}
        {sources.map((source, index) => (
          <ReplenishItemForm
            index={index}
            item={source}
            type="pick"
            maxQuantityToAdd={
              replenishTask.quantity -
              sources.reduce(
                (total, s) =>
                  s.quantity &&
                  s.bin !== source.bin &&
                  s.inventory_number !== source.inventory_number
                    ? total + s.quantity
                    : total,
                0,
              )
            }
            onAllFilled={handleUpdateSource}
            onChanged={handleOnChange}
            onReplace={handleReplaceBatch}
          />
        ))}
        <Grid container style={{ marginTop: 8 }}>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <S.RedButtonText
              onClick={handleRemoveBatch}
              disabled={sources.length === 1}
            >
              REMOVE BATCH
            </S.RedButtonText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <S.BlueButtonText onClick={handleAddBatch}>
              <Add /> ADD BATCH
            </S.BlueButtonText>
          </Grid>
        </Grid>
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer */}
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton onClick={handleBackToSkuListPage}>
              BACK TO SKU LIST
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton onClick={handleGoToNextSku} disabled={!nextSku}>
              NEXT SKU
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>
    </>
  );
};
