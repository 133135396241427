import React from 'react';
import { useSelector } from 'react-redux';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { RootReducerInterface } from 'redux-stores/reducers';

import { config } from './packTask.config';
import * as S from './PackTaskPage.styles';

// TODO to be implemented
const PackTaskPage: React.FC = () => {
  const { assignedTasks } = useSelector(
    (state: RootReducerInterface) => state.pickingTask,
  );

  return (
    <div>
      <Header title="Pack Items" />
      <TableComponent
        config={config}
        data={assignedTasks[0].items || []}
        onRowClick={(): void => {
          console.log('To be implemented');
        }}
      />
      <S.Button>Resolve</S.Button>
    </div>
  );
};

export default PackTaskPage;
