import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckCircleOutline, ErrorOutlineOutlined } from '@mui/icons-material';
import { Card, CardContent, Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import * as colors from 'commons/styles/colors';
import { PickingListBySKUs } from 'commons/types';
import { formatDate } from 'commons/utils/formatDate';
import {
  getPickTaskBySKU,
  postComplete,
  validatePickingList,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';

import S from './PickSummary.style';

export const PickSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const updatePickTaskBySKU = async (): Promise<void> => {
      dispatch(getPickTaskBySKU());
    };

    updatePickTaskBySKU();
  }, [dispatch]);

  const { tasksBySKUs } = useSelector(
    (state: RootReducerInterface) => state.pickingTask,
  );
  const [isCompleteButtonDisabled, setCompleteButtonDisabled] = useState(false);
  const [completedSKU, setCompletedSKU] = useState<PickingListBySKUs[]>([]);
  const [incompleteSKU, setIncompleteSKU] = useState<PickingListBySKUs[]>([]);

  useMemo(() => {
    const _completedSKU: PickingListBySKUs[] = [];
    const _incompleteSKU: PickingListBySKUs[] = [];
    tasksBySKUs.forEach((task) => {
      task.details.forEach((detail) => {
        const totalQty = detail.picked_actual.reduce(
          (total, picked) => total + picked.quantity,
          0,
        );
        if (totalQty === detail.quantity) {
          const index = _completedSKU.findIndex(
            (sku) => sku.sku_code === task.sku_code,
          );
          if (index >= 0) {
            _completedSKU[index].details.push(detail);
          } else {
            _completedSKU.push({
              sku_code: task.sku_code,
              sku_name: task.sku_name,
              pick_recommendation: [],
              total_quantity: task.total_quantity,
              details: [detail],
            });
          }
        } else {
          const index = _incompleteSKU.findIndex(
            (sku) => sku.sku_code === task.sku_code,
          );
          if (index >= 0) {
            _incompleteSKU[index].details.push(detail);
          } else {
            _incompleteSKU.push({
              sku_code: task.sku_code,
              sku_name: task.sku_name,
              pick_recommendation: [],
              total_quantity: task.total_quantity,
              details: [detail],
            });
          }
        }
      });
    });
    setCompletedSKU(_completedSKU);
    setIncompleteSKU(_incompleteSKU);
  }, [tasksBySKUs]);

  useEffect(() => {
    setCompleteButtonDisabled(
      validatePickingList(tasksBySKUs).validation === 'error',
    );
  }, [tasksBySKUs]);

  const renderBasketSummary = (
    task: PickingListBySKUs,
  ): React.JSX.Element[][] =>
    task.details.map((detail) =>
      detail.picked_actual.map((picked) => (
        <Card variant="outlined" style={{ margin: '8px 0' }}>
          <CardContent style={{ padding: 8 }}>
            <Grid container>
              <Grid item xs={5}>
                <S.SummaryName>Basket Number</S.SummaryName>
              </Grid>
              <Grid item xs={7}>
                <S.SummaryValue>{picked.basket}</S.SummaryValue>
              </Grid>
              <Grid item xs={4} sm={5}>
                <S.SummaryName>SO Number</S.SummaryName>
              </Grid>
              <Grid item xs={8} sm={7}>
                <S.SummaryValue>{detail.po_number}</S.SummaryValue>
              </Grid>
              <Grid item xs={5}>
                <S.SummaryName>Bin</S.SummaryName>
              </Grid>
              <Grid item xs={7}>
                <S.SummaryValue>{picked.bin}</S.SummaryValue>
              </Grid>
              <Grid item xs={5}>
                <S.SummaryName>Batch Number</S.SummaryName>
              </Grid>
              <Grid item xs={7}>
                <S.SummaryValue>{picked.inventory_number}</S.SummaryValue>
              </Grid>
              <Grid item xs={5}>
                <S.SummaryName>Expiry Date</S.SummaryName>
              </Grid>
              <Grid item xs={7}>
                <S.SummaryValue>
                  {formatDate(picked.expiry_date)}
                </S.SummaryValue>
              </Grid>
              <Grid item xs={5}>
                <S.SummaryName>Quantity Picked</S.SummaryName>
              </Grid>
              <Grid item xs={7}>
                <S.SummaryValue>{picked.quantity}</S.SummaryValue>
              </Grid>
              <Grid item xs={5}>
                <S.SummaryName>Priority</S.SummaryName>
              </Grid>
              <Grid item xs={7}>
                <S.SummaryValue>{detail.priority}</S.SummaryValue>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )),
    );

  const completeTask = async (): Promise<void> => {
    const validationStatus = validatePickingList(tasksBySKUs);
    if (validationStatus.validation === 'error') {
      dispatch(
        snackbarSetData({
          open: true,
          message: validationStatus.message,
          color: colors.PRIMARY_RED,
        }),
      );
      return;
    }
    // TODO warn if validation === warning

    const { success, ...snackBarState } = await postComplete();
    dispatch(snackbarSetData(snackBarState));

    /* goes back to basket assignment */
    if (success) {
      navigate('/assign-task');
    }
  };

  const renderSKUSummary = (
    pickingListBySKUs: PickingListBySKUs[],
  ): React.JSX.Element[] =>
    pickingListBySKUs.map((task) => (
      <S.SKUSummaryWrapper>
        <Grid container style={{ fontWeight: 700 }}>
          <Grid item xs={6}>
            {task.sku_code}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right', color: '#737373' }}>
            QTY ORDER:{' '}
            <span style={{ color: '#000' }}>{task.total_quantity}</span>
          </Grid>
        </Grid>
        {renderBasketSummary(task)}
      </S.SKUSummaryWrapper>
    ));

  return (
    <>
      <Header
        title="Pick Summary"
        prevPageHandler={() => {
          navigate('/pick-list');
        }}
      />

      {/* Content Section */}
      <S.ContentWrapper>
        {incompleteSKU.length > 0 && (
          <>
            <S.HeaderWrapper>
              <S.Header color={colors.PRIMARY_YELLOW}>
                <ErrorOutlineOutlined />
                Incomplete Order
              </S.Header>
              <S.SubHeader>Below is the summary of the task.</S.SubHeader>
            </S.HeaderWrapper>
            {renderSKUSummary(incompleteSKU)}
          </>
        )}

        {completedSKU.length > 0 && (
          <>
            <S.HeaderWrapper>
              <S.Header color={colors.PRIMARY_GREEN}>
                <CheckCircleOutline />
                Picking Completed
              </S.Header>
              <S.SubHeader>Below is the summary of the task.</S.SubHeader>
            </S.HeaderWrapper>
            {renderSKUSummary(completedSKU)}
          </>
        )}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer Section */}
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton onClick={(): void => navigate('/pick-list')}>
              GO BACK
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton
              disabled={isCompleteButtonDisabled}
              onClick={completeTask}
            >
              COMPLETE TASK
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>
    </>
  );
};
