import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import PrivateRoute from 'commons/utils/privateRoute';
import CheckTaskPage from 'pages/check/check-task/CheckTask';
import OrderListPage from 'pages/dashboard/order-lists/OrderList';
import ReplenishListPage from 'pages/dashboard/replenish-list/ReplenishList';
import DashboardPage from 'pages/dashboard/summaries/Dashboard';
import UserListPage from 'pages/dashboard/user-list/UserList';
import HomePage from 'pages/homepage/HomePage';
import CodeFlowCallback from 'pages/login/CodeFlowCallback';
import Login from 'pages/login/Login';
import PackTaskPage from 'pages/pack/pack-task/PackTaskPage';
import AssignSOPage from 'pages/pick/assign-task/AssignTaskPage';
import PickItemPage from 'pages/pick/pick-item/PickItemPage';
import { PickSummaryPage } from 'pages/pick/pick-summary/PickSummary';
import PickingListPage from 'pages/pick/picking-list/PickingListPage';
import AssignSKUPage from 'pages/replenish/assign-replenish-task/AssignSKUPage';
import {
  ReplenishListSourcePage,
  ReplenishListDestinationPage,
} from 'pages/replenish/replenish-list';
import { ReplenishSummaryPage } from 'pages/replenish/replenish-summary';
import {
  ReplenishTaskDestinationPage,
  ReplenishTaskSourcePage,
} from 'pages/replenish/replenish-task';

export const AppRouter = (): React.JSX.Element => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/code-flow-callback" element={<CodeFlowCallback />} />

    <Route
      path="/home"
      element={
        <PrivateRoute
          roles={[UserRolesEnum.ADMIN, UserRolesEnum.SUPER_ADMIN]}
        />
      }
    >
      <Route path="/home" element={<HomePage />} />
    </Route>

    <Route
      path="/assign-replenish"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.REPLENISH,
          ]}
        />
      }
    >
      <Route path="/assign-replenish" element={<AssignSKUPage />} />
    </Route>

    <Route
      path="/replenish-list/source"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.REPLENISH,
          ]}
        />
      }
    >
      <Route
        path="/replenish-list/source"
        element={<ReplenishListSourcePage />}
      />
    </Route>

    <Route
      path="/replenish-list/destination"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.REPLENISH,
          ]}
        />
      }
    >
      <Route
        path="/replenish-list/destination"
        element={<ReplenishListDestinationPage />}
      />
    </Route>

    <Route
      path="/replenish-task/source/:skuId"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.REPLENISH,
          ]}
        />
      }
    >
      <Route
        path="/replenish-task/source/:skuId"
        element={<ReplenishTaskSourcePage />}
      />
    </Route>

    <Route
      path="/replenish-task/destination/:skuId"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.REPLENISH,
          ]}
        />
      }
    >
      <Route
        path="/replenish-task/destination/:skuId"
        element={<ReplenishTaskDestinationPage />}
      />
    </Route>

    <Route
      path="/assign-task"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.ADMIN,
            UserRolesEnum.PICKER,
            UserRolesEnum.PICKER_LEAD,
          ]}
        />
      }
    >
      <Route path="/assign-task" element={<AssignSOPage />} />
    </Route>

    <Route
      path="/replenish-summary"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.REPLENISH,
          ]}
        />
      }
    >
      <Route path="/replenish-summary" element={<ReplenishSummaryPage />} />
    </Route>

    <Route
      path="/pick-list"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.PICKER,
            UserRolesEnum.PICKER_LEAD,
          ]}
        />
      }
    >
      <Route path="/pick-list" element={<PickingListPage />} />
    </Route>

    <Route
      path="/pick-item"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.PICKER,
            UserRolesEnum.PICKER_LEAD,
          ]}
        />
      }
    >
      <Route path="/pick-item" element={<PickItemPage />} />
    </Route>

    <Route
      path="/pick-summary"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.PICKER,
            UserRolesEnum.PICKER_LEAD,
          ]}
        />
      }
    >
      <Route path="/pick-summary" element={<PickSummaryPage />} />
    </Route>

    <Route
      path="/dashboard"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.PICKER_LEAD,
          ]}
        />
      }
    >
      <Route path="/dashboard" element={<DashboardPage />} />
    </Route>

    <Route
      path="/order-item"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.PICKER_LEAD,
          ]}
        />
      }
    >
      <Route path="/order-item" element={<OrderListPage />} />
    </Route>
    <Route
      path="/users"
      element={
        <PrivateRoute
          roles={[UserRolesEnum.ADMIN, UserRolesEnum.SUPER_ADMIN]}
        />
      }
    >
      <Route path="/users" element={<UserListPage />} />
    </Route>
    <Route
      path="/replenish-item"
      element={
        <PrivateRoute
          roles={[UserRolesEnum.ADMIN, UserRolesEnum.SUPER_ADMIN]}
        />
      }
    >
      <Route path="/replenish-item" element={<ReplenishListPage />} />
    </Route>

    <Route path="/check-task" element={<CheckTaskPage />} />
    <Route path="/pack-task" element={<PackTaskPage />} />
    <Route path="/not-found" element={<div>Not found</div>} />
    <Route path="/*" element={<Navigate to="/" />} />
  </Routes>
);
